import bannerImg from "../../assets/images/banners/g-banner-sml.jpg";
import { Header } from "../../components/Header/Header";
import styles from "./CustomerComplaintsPolicy.module.css"

export const CustomerComplaints = () => {
    return (
        <>
            <Header text="Customer Complaints Policy" bgColour="#01402E" img={bannerImg} />
            <div className={styles.privacyContainer}>
                <h2 className={styles.privacyTitle}>Customer Complaints Policy</h2>

                <p>To view our Customer Complaints, Concerns and Compliments policy, please <a href="https://www.growthco.uk/about-us/policies/customer-complaints-concerns-and-compliments-policy/" target="_blank" rel="noreferrer" className={styles.link}>click here.</a></p>

            </div>
        </>
    );
};
import React from "react";
import { Link } from "react-router-dom";

import styles from "./Footer.module.css";

import geLogo from "../../assets/images/logos/green-economy-logo-drk-green.svg";
import gcLogo from "../../assets/images/logos/footer-logos/gc-logo.svg";
import gmca from "../../assets/images/logos/footer-logos/gmca-green.png";
import ukgov from "../../assets/images/logos/footer-logos/UKGov-green.png";
import ukspf from "../../assets/images/logos/footer-logos/UKSPF-green.png";
import cyc from "../../assets/images/logos/footer-logos/cyc-green.png";
import facebook from "../../assets/images/logos/social/facebook.svg";
import x from "../../assets/images/logos/social/x-icon.svg";
import linkedin from "../../assets/images/logos/social/linkedin.svg";

import Btn from "../../components/Button/Button";

export const Footer = () => {

    let year = new Date().getFullYear();

    return (

         <div className={styles.outer}>
            <section className={styles.footerContainer}>
                <div className={styles.sectionFooterContainer}>
                    <div className={`${styles.footerContent1}`}>
                        <Link as={Link} to={process.env.REACT_APP_GI_URI}>
                            <img className={`${styles.logo}`} src={geLogo} alt="Green Economy" />
                        </Link>
                    </div>
                    <div className={`${styles.footerContent2}`}>
                        <h3 style={{}}>Green Economy</h3>
                        <p>Lee House</p>
                        <p>90 Great Bridgewater Street</p>
                        <p>Manchester</p>
                        <p className={`${styles.footerContentMargin}`}>M1 5JW</p>
                        <p>
                            <b>T:</b> 0161 359 3050
                        </p>
                        <p>
                            <b>E:</b> <a href="mailto:info@greeneconomy.co.uk" className={styles.hoverStyle}>info@greeneconomy.co.uk</a>
                        </p>
                       
                    </div>
                    <div className={`${styles.footerContent3}`}>
                        <h3 style={{}}>Useful Information</h3>
                        <Link as={Link} to="/privacy">
                            <p className={`${styles.footerContentMargin} ${styles.hoverStyle}`}>Privacy Policy</p>
                        </Link>
                        <Link as={Link} to="/cookie-policy">
                            <p className={`${styles.footerContentMargin} ${styles.hoverStyle}`}>Cookie Policy</p>
                        </Link>
                        <Link as={Link} to="/terms-and-conditions">
                            <p className={`${styles.footerContentMargin} ${styles.hoverStyle}`}>Terms & Conditions</p>
                        </Link>
                        <Link as={Link} to="/accessibility-statement">
                            <p className={`${styles.footerContentMargin} ${styles.hoverStyle}`}>Accessibility Statement</p>
                        </Link>
                        <a 
                            href="https://www.growthco.uk/about-us/policies/modern-slavery-and-human-trafficking-statement/"
                            target='_blank'
                            rel="noreferrer"
                        >
                            <p className={`${styles.footerContentMargin} ${styles.hoverStyle}`}>Modern Slavery Statement</p>
                        </a>
                       
                        <Link as={Link} to="/customer-complaints-policy">
                            <p className={`${styles.hoverStyle}`}>Customer Complaints Policy</p>
                        </Link>
                        
                    </div>
                   
                    <div className={`${styles.footerContent4}`}>
                        <a
                            href="https://forms.zohopublic.eu/greenintelligence/form/GreenEconomyenquiryform/formperma/PwfDtxRrxNDSRB4qkBT67WmBInEXA6lCbH3JGauLDE4"
                            target='_blank'
                            rel="noreferrer"
                        >
                            <Btn text="Get in touch" color="DarkGreenOutline" />
                        </a>
                    </div>
                </div>
                <div className={`${styles.footerLogosContainer}`}>
                    <h3 style={{}}>Part of The Growth Company</h3>
                    <div className={`${styles.footerLogos}`}>
                        <img className={styles.footerLogo} src={gcLogo} alt="The Growth Company" />
                        <img className={styles.footerLogo} src={gmca} alt="The Greater Manchester Combined Authority" />
                        <img className={styles.footerLogo} src={ukgov} alt="Funded by UK Government" />
                        <img className={styles.footerLogo} src={ukspf} alt="West Yorkshire Combined Authority" />
                        <img className={styles.footerLogo} src={cyc} alt="City of York Council" />
                    </div>
                </div>
                <div className={styles.footerBottomContainer}>
                    <div className={styles.regInfo}>
                        <p style={{}}>&copy; {year} The Growth Company</p>
                        <p>Registered in England No:&nbsp;03192592</p>
                        <p>VAT No:&nbsp;727102071</p>
                    </div>
                    <div className={styles.footerSocials}>
                        <a className={styles.hoverStyle} href="https://www.facebook.com/Green-Economy-108275631897116" target="_blank" rel="noreferrer">
                            <img src={facebook} alt="Facebook" class="icons" className={styles.icons} />
                        </a>

                        <a className={styles.hoverStyle} href="https://twitter.com/UKGreenEconomy" target="_blank" rel="noreferrer">
                            <img src={x} alt="X" className={styles.icons} />
                        </a>

                        <a className={styles.hoverStyle} href="https://www.linkedin.com/company/greeneconomyuk/" target="_blank" rel="noreferrer">
                            <img src={linkedin} alt="LinkedIn" className={styles.icons} />
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
};

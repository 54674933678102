import React, { useState, useEffect } from "react";
import { setDescription, setTitle } from "../../utils";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "../../components/Oidc/Authentication";
import styles from "./membership.module.css";
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import heroImage from "../../assets/images/members/membership-hero.jpg";
import bannerImg from "../../assets/images/members/eligible-businesses.jpg";
import { getPlans } from "../../utils";
import { PageCard } from "../../components/PageCard/PageCard";
import freeMemberImg from "../../assets/images/members/free-membership.svg";
import fullMemberImg from "../../assets/images/members/full-membership.svg";

const Membership = () => {

    const [plans, setPlans] = useState(null);
    const [buildplans, setBuildPlans] = useState(null);
    const [freePlan, setFreePlan] = useState(null);

    useEffect(() => {
        setTitle("Green Economy | Membership: Join Green Economy");
        setDescription("Working with green tech firms to raise your profile, provide access to new supply chains and develop your business network.")
    }, []);

    useEffect(() => {
        const fetchPlans = async () => {
            const plans = await getPlans();
            if (plans) {
                setPlans(plans);
                let _plans = plans.filter(plan => plan.plan_code.includes("BUILD"));
                _plans = _plans.sort((a, b) => {
                    return parseInt(a.recurring_price) - parseInt(b.recurring_price);
                })
                setBuildPlans(_plans);
                let free = plans.find(p => p.plan_code === "MARKETPLACE");
                setFreePlan(free);
            }
        };
        fetchPlans();
    }, []);

    return (

        <div>

            <HeroBanner
                title="Membership"
                subTitle="Create your <b style='color: #02BE7C;'>marketplace profile</b> and gain access to a host of business <b style='color: #02BE7C;'>growth benefits</b>"
                pageLink={false}
                externalLink={false}
                emailLink={false}
                heroImage={heroImage}
                imageLeft={false}
                bgGreen={false}
                buttonStyle="Green HoverWhite"
                vAlign="center"
            />
        
            <div className={styles.memberContainer}>
                <div className={styles.memberContentContainer}>
                    <h2><b>Join Green Economy</b></h2>
                    <p className="">
                        Do you install, supply, design, manufacture or distribute green technologies or services? Green Economy brings together the installers,
                        tradespeople and professionals we need to get the UK on the path to a sustainable, net zero economy into one single, searchable network,
                        whilst offering the sector a host of other benefits to win new business and grow.
                    </p>
                    <h4 className=""><b>Green Economy offers <span className={styles.green}>two levels</span> of membership:</b> </h4>
                </div>
                <div className={styles.grid2Cols}>
                    <UnauthenticatedTemplate>
                        <PageCard
                            width="100%"
                            header="Free Spark Membership"
                            subHeader="Create a full, free profile on our marketplace to increase your visibility and to access direct sales enquiries through our site."
                            hasLine={true}
                            text=""
                            subText={freePlan?.store_markup_description.split('|')}
                            page="public"
                            hasLogo={false}
                            link={freePlan?.url}
                            styling="membership"
                            btnText={freePlan?.name}
                            externalLink={true}
                            listsubtext={true}
                        />
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <PageCard
                            width="100%"
                            header="Spark - Create a full, free profile on our marketplace to increase your visibility and to access direct sales enquiries through our site."
                            text={freePlan?.description}
                            subText={freePlan?.store_markup_description.split('|')}
                            page="public"
                            hasLogo={false}
                            styling="membership"
                            listsubtext={true}
                        />
                    </AuthenticatedTemplate>
                    <div className={`${styles.imageContainer}`}>
                        <img alt="free" className={`${styles.imageWidth}`} src={freeMemberImg} />
                    </div>
                </div>

                <div className={styles.grid2Cols} >
                    <div className={`${styles.imageContainer} ${styles.gridRow2}`}>
                        <img alt="full" className={`${styles.imageWidth}`} src={fullMemberImg} />
                    </div>
                    <PageCard
                        width="100%"
                        header="Full membership"
                        subHeader="This enhanced package provides a host of opportunities to drive business growth including:"
                        hasLine={true}
                        text=""
                        subText={["A searchable profile on the Marketplace with direct enquiries from buyers (included with free profile) ",
                            "Access to exclusive sales and tender opportunities, including a weekly email of the latest opportunities",
                            "Networking and events, including invitations to Meet the Buyer sessions",
                            "Complimentary access to the Green Leaders events programme", "Additional resources including market intelligence and business support guidance."]}
                        hasLogo={false}
                        styling="membershipFull"
                        listsubtext={true}
                    />
                </div>

                <div className={styles.grid4Cols}>
                    {
                        buildplans?.map(plan => (                           
                            <div key={plan.plan_code} className={styles.memberCard}>
                                <UnauthenticatedTemplate>
                                    <PageCard
                                        width="100%"
                                        header={"&#163;" + plan.recurring_price + ".00 per " + plan.interval_unit.replace("s", "")}
                                        subHeader={plan.description}
                                        hasLine={true}
                                        text=""
                                        subText={plan.store_markup_description.split('|')}
                                        page="public"
                                        hasLogo={false}
                                        link={plan.url}
                                        styling="membershipSub"
                                        btnText={plan.name}
                                        externalLink={true}
                                        listsubtext={true}
                                    />
                                </UnauthenticatedTemplate>
                                <AuthenticatedTemplate>
                                    <PageCard
                                        width="100%"
                                        header={"&#163;" + plan.recurring_price + ".00 - You are already registered."}
                                        text={plan.description}
                                        subText={plan.store_markup_description.split('|')}
                                        page="public"
                                        hasLogo={false}
                                        styling="membershipSub"
                                        listsubtext={true}
                                    />
                                </AuthenticatedTemplate>
                            </div>
                        ))
                    }
                </div>
                
            </div>

            <HeroBanner
                title="Eligible businesses"
                subTitle="Businesses operating in <a href={process.env.REACT_APP_GI_URI + ' /services/grow-your-green-economy/gm/'} className='PLinkTo'>Greater Manchester</a> and <a href={process.env.REACT_APP_GI_URI + ' /services/grow-your-green-economy/west-yorkshire/'} className='PLinkTo'>West Yorkshire</a> can access the Full Membership package at no cost to their business."
                text="For the definition of business size <a href='https://www.gov.uk/government/publications/fcdo-small-to-medium-sized-enterprise-sme-action-plan/small-to-medium-sized-enterprise-sme-action-plan#what-is-an-sme' className='PLinkTo' target='_blank' rel='noopener noreferrer'>visit the UK Government's website.</a>"
                pageLink={false}
                externalLink={false}
                emailLink="info@greeneconomy.co.uk"
                heroImage={bannerImg}
                imageLeft={true}
                bgGreen={true}
                buttonText="For all membership enquiries"
                buttonStyle="Green HoverWhite"
                vAlign="center"
            />

        </div>
    );
};

export default Membership;
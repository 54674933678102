import React, { useState, useEffect } from "react";
import styles from './HeroBanner.module.css';
import overlay1 from "../../assets/images/masks/filter-overlay-1.png";
import overlay2 from "../../assets/images/masks/filter-overlay-2.png";
import MailModal from "../../components/MailModal/EnquiryModal";
import Btn from "../../components/Button/Button";
import { Link } from "react-router-dom";

const HeroBanner = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderHTML = (rawHTML) => {
        
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });
    };

    const handleClick = () => {
        handleShow();
    };

    return (
        <div className={props.bgGreen ? `${styles.waveGreen}` : `${styles.wave}` }>
            <div className={props.imageLeft ? `${styles.grid2ColsLeft}` : `${styles.grid2Cols}`} style={{ alignItems: `${props.vAlign}` }}>
                {props.imageLeft ? 
                    <div className={`${styles.imageContainer} ${styles.gridRow1}`}>
                        <img src={overlay2} alt="" className={`${styles.overlay} ${styles.small}`} />
                        <div style={{ backgroundImage: `url(${props.heroImage})` }} className={`${styles.imageBg} ${styles.small}`}>
                        </div>
                    </div>
                    :
                    <div style={{ display: 'none' }} ></div>
                }
                <div className={props.imageLeft ? `${styles.colContentContainerRight}` : `${styles.colContentContainer}`}>
                    <h1>{renderHTML(props.title)}</h1>
                    <h2>{renderHTML(props.subTitle)}</h2>
                    <p>{renderHTML(props.text)}</p>
                    {props.externalLink ?
                        <div>
                            <a className={styles.link} href={props.externalLink}>
                                <Btn text={props.buttonText} color={props.buttonStyle} />
                            </a>
                        </div>
                        :
                        <div style={{display: 'none'}} ></div>
                    }
                    {props.pageLink ?
                        <div>
                            <Link to={props.pageLink}>
                                <Btn text={props.buttonText} color={props.buttonStyle} />
                            </Link>
                        </div>
                        :
                        <div style={{ display: 'none' }} ></div>
                    }
                    {props.emailLink ?
                        <div>
                            <MailModal show={show} handleClose={handleClose} title={props.buttonText} email={props.emailLink} template={3} />
                            <a onClick={handleClick} className="">
                                <Btn text={props.buttonText} color={props.buttonStyle} />
                            </a>
                        </div>
                        :
                        <div style={{ display: 'none' }} ></div>
                    }
                </div>
                {props.imageLeft ?
                    <div style={{ display: 'none' }} ></div>
                    :
                    <div className={`${styles.imageContainer} ${styles.gridRow1}`}>
                        <img src={overlay1} alt="" className={styles.overlay} />
                        <div style={{ backgroundImage: `url(${props.heroImage})` }} className={`${styles.imageBg}`}></div>
                    </div>
                }
            </div>
        </div >
    )
}

export default HeroBanner;
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Styles from "./NavMenu.module.css";
import logo from "../../assets/images/logos/green-economy-logo.svg";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "../Oidc/Authentication";
import Login from "../Oidc/Login";
import { Logout } from "../Oidc/Logout";
import "./NavMenu.css";
import { useLocation } from "react-router-dom";


export const NavMenu = () => {
    const location = useLocation();
    const path = location.pathname;

    return (
        <>
            <Navbar variant="dark" className={`${Styles.navbar} navbar`} expand="lg" tabIndex={-1}>
                <Navbar.Brand className={Styles.brand} href={process.env.REACT_APP_GI_URI}>
                    <img className={Styles.logo} src={logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className={`gcicon-menu`} />
                <Navbar.Collapse id="basic-navbar-nav" className={Styles.navLinkContainer}>
                    <Nav className="">
                        {/*Public pages*/}
                        <UnauthenticatedTemplate>
                            <NavDropdown title="Business Support" id="busunessSupport" renderMenuOnMount={true} show={false} className={`${Styles.navLink} ${path.includes("/business-support") ? Styles.current : null}`} >
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/business-support/decarbonise"}>Decarbonise</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/business-support/green-tech-growth"}>Green Tech Growth</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/business-support/grow-your-green-economy"}>Grow your green economy</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Tools" id="Tools" renderMenuOnMount={true} show={false} className={`${Styles.navLink} ${path === "/marketplace" || path === "/resources" ? Styles.current : null}`}>
                                <NavDropdown.Item href="/marketplace">Marketplace</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" href="https://carboncalculator.greeneconomy.co.uk/" >Carbon Calculator</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/tools/resources"}>Resources</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href={process.env.REACT_APP_GI_URI + "/events-and-training"} className={`${Styles.navLink} ${path === "/events" ? Styles.current : null}`}>Events and Training</Nav.Link>
                            <NavDropdown title="News and Resources" id="newsresources" renderMenuOnMount={true} show={false} className={`${Styles.navLink} ${path === "/news" || path === "/case-studies" || path === "/insights" || path === "/reports" || path === "/resources" ? Styles.current : null}`}>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/news"}>News</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/case-studies"}>Case Studies</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/insights"}>Insights</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/reports"}>Reports</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="About Us" id="about" renderMenuOnMount={true} show={false} className={`${Styles.navLink} ${path.includes("/about-us") ? Styles.current : null}`}>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/about-us/who-we-are/"}>Who We Are</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/about-us/strategic-partnerships"}>Strategic Partnerships</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/membership" className={`${Styles.navLink} ${path === "/membership" ? Styles.current : null}`}>Membership</Nav.Link>
                        </UnauthenticatedTemplate>

                        {/*Public & Private pages*/}
                        <AuthenticatedTemplate>
                            <NavDropdown title="Business Support" id="busunessSupport" renderMenuOnMount={true} show={false} className={`${Styles.navLink} ${path.includes("/business-support") ? Styles.current : null}`} >
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/business-support/decarbonise"}>Decarbonise</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/business-support/green-tech-growth"}>Green Tech Growth</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/business-support/grow-your-green-economy"}>Grow your green economy</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/marketplace" className={`${Styles.navLink} ${path === "/marketplace" ? Styles.current : null}`}>Marketplace</Nav.Link>
                            <Nav.Link href="/events" className={`${Styles.navLink} ${path === "/events" ? Styles.current : null}`}>Events and Training</Nav.Link>
                            <NavDropdown title="News and Resources" id="newsresources" renderMenuOnMount={true} show={false} className={`${Styles.navLink} ${path === "/news" || path === "/case-studies" || path === "/insights" || path === "/reports" || path === "/resources" ? Styles.current : null}`}>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/news"}>News</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/case-studies"}>Case Studies</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/insights"}>Insights</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/reports"}>Reports</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/resources"}>Resources</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="About Us" id="about" renderMenuOnMount={true} show={false} className={`${Styles.navLink} ${path.includes("/about-us") ? Styles.current : null}`}>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/about-us/who-we-are/"}>Who We Are</NavDropdown.Item>
                                <NavDropdown.Item href={process.env.REACT_APP_GI_URI + "/about-us/strategic-partnerships"}>Strategic Partnerships</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/dashboard" className={`${path === "/dashboard" ? Styles.current : null}`}>Dashboard</Nav.Link>
                            {/*{process.env.REACT_APP_GI_MEMBERSHIP_ENABLED === "true" &&*/}
                            {/*    <Logout />*/}
                            {/*}*/}
                        </AuthenticatedTemplate>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

import styles from "./Header.module.css";

export const Header = ({ text, bgColour, img }) => {
    return (
        <div className={styles.title} style={{ backgroundColor: "#01402E" }}>
            <h1 data-testid="title">{text}</h1>
            <div className={styles.icon}>
               {/* <img src={img} alt="banner" />*/}
            </div>
        </div>
    );
};

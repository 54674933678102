import React from "react";
import { Link } from "react-router-dom";

import styles from "./PageCard.module.css";
import "./PageCard.css";

import Btn from "../Button/Button";


export const PageCard = ({ width, header, subHeader, hasLine, text, page, hasLogo, logo, link, styling, btnText = "Take Me There", subText = "", externalLink = false, listsubtext = false, handleshow=null }) => {

    const renderHTML = (rawHTML) => {
        return React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    };

    const setlist = (arr) => {
        return arr.map((t, idx) => {
            return <li key={idx}>{renderHTML(t)}</li>
        })
    }

    return (
        <div className={styling === 'link' || styling === 'image' || styling === 'imageFour' || styling === 'imageFive' || styling === 'sub' || styling === 'subSingle' || styling === 'standardNoImage' || styling === 'standardNoImageLine' || styling === 'standardNoImageOver' || styling === 'membership' || styling === 'membershipSub' || styling === 'membershipFull' ? `${styles.linkcontainer} ${styling + "Card"}` : `${styles.container} ${styling + "Card"}`} style={{ width: width }}>
            <div>
            {hasLogo && (
                <div className={styling !== 'image' && styling !== 'imageFour' && styling !== 'imageFive' ? styles.logo : styles.image}>
                    <img src={logo} alt={`${page} logo`} />
                </div>
            )}
            <div className='header'>{renderHTML(header)}</div>
            {subHeader ?
                <div className={styles.subHeader}>{renderHTML(subHeader)}</div>
                :
                <div style={{display:`none`}} ></div>
            }
            {hasLine && (
                <hr className={styles.line}></hr>
            )}
            <div className={styles.text}>{renderHTML(text)}</div>
            <div className={styles.subText }>
            {subText !== "" && (
                <>
                    { listsubtext === false ?
                        renderHTML(subText)
                        : <ul style={{ textAlign: 'center' }}>{setlist(subText)}</ul>
                           
                    }
                </> 
            )}
                </div>
            </div>
            <div style={{position:"relative"} }>
                {link && externalLink===false && (
                    <Link to={`/${link}`} >
                        <Btn text={btnText} color={page === "marketplace" || styling === "light" || styling === "standard" ? "LightGreen" : "Green"} />
                    </Link>
                )}
                {link && externalLink===true && (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <Btn text={btnText} color={page === "membership" || styling === "membership" || styling === "membershipSub" || styling === "membershipFull" ? "Green" : "Green"} />


                    </a>
                )}
                {handleshow && (
                    <Btn text={btnText} onClick={handleshow} color="Green" />
                )}
            </div>
        </div>
    );
};
